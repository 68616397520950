import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import { 
  TextField, Button, Grid, Typography, Checkbox, FormControlLabel, Box, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import CommonLayout from '../components/CommonLayout';
import PermissionsManager from '../components/PermissionsManager';
import LoadingSubmitButton from '../components/LoadingSubmitButton';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#103C6E',
          color: 'white',
          '&:hover': {
            backgroundColor: '#0d3157',
          },
          '&:disabled': {
            backgroundColor: '#B9EDE7',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          textAlign: 'right',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(-14px, -9px) scale(0.75)',
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 16px) scale(1)',
          },
        },
      },
    },
  },
});

const hospitalRoles = [
  { value: 'SENIOR_PHYSICIAN', label: 'רופא בכיר' },
  { value: 'MEDICAL_RESIDENT', label: 'מתמחה' },
  { value: 'NURSE', label: 'אחות' },
  { value: 'PARAMEDICAL', label: 'פרא-רפואי' },
  { value: 'ADMINISTRATION', label: 'אדמיניסטרציה' },
  { value: 'OTHER', label: 'אחר' },
];

function AddUser() {
    const { user: loggedInUser } = useContext(UserContext);
    const [user, setUser] = useState({
      name: '',
      email: '',
      phone: '',
      password: '',
      isSuperAdmin: false,
      roles: [],
      hospitalRole: ''
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [hospitals, setHospitals] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
  
    useEffect(() => {
      API.get('/api/hospitals').then(response => {
        const hospitals = response.data;
        setHospitals(hospitals);
        if (hospitals.length === 1) {            
            setUser({ ...user, roles: [{ hospital: hospitals[0]._id, role: 'USER' }] });
        }
      }).catch(error => console.error('Fetching hospitals failed:', error));
    }, []);
  
    const handleSuperAdminChange = (value) => {
      setUser({ ...user, isSuperAdmin: value});
    }
  
    const handlePasswordChange = (e) => {
      setUser({ ...user, password: e.target.value });
      if (confirmPassword && e.target.value !== confirmPassword) {
        setPasswordError('סיסמאות לא תואמות');
      } else {
        setPasswordError('');
      }
    };
  
    const handleConfirmPasswordChange = (e) => {
      setConfirmPassword(e.target.value);
      if (user.password && user.password.length < 6) {
        setPasswordError('סיסמה צריכה להכיל מינימום 6 תוים');
      } else if (user.password && e.target.value !== user.password) {
        setPasswordError('סיסמאות לא תואמות');
      } else {
        setPasswordError('');
      }
    };

  const handleRoleChange = (index, field, value) => {
    const newRoles = user.roles.map((role, idx) => {
      if (idx === index) {
        return { ...role, [field]: value };
      }
      return role;
    });
    setUser({ ...user, roles: newRoles });
  };

  const addRole = () => {
    setUser({ ...user, roles: [...user.roles, { hospital: hospitals.length === 1? hospitals[0]._id: '', role: 'USER' }] });
  };

  const removeRole = index => {
    setUser({ ...user, roles: user.roles.filter((_, idx) => idx !== index) });
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.password !== confirmPassword) {
      setPasswordError('סיסמאות לא תואמות');
      return;
    }
    setIsSubmitting(true);
    try {
      await API.post('/api/users', user);
      navigate('/users');
    } catch (error) {
      console.error('Adding user failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CommonLayout>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="add-user-container">
            <div className="user-header">
              <Typography variant="h5" style={{fontWeight:"bold", padding: "5px", marginBottom: "20px", textAlign: "center"}}>
                משתמש חדש
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="שם"
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="אימייל"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="סיסמה"
                    type="password"
                    value={user.password}
                    onChange={handlePasswordChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="חזור על הסיסמה"
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="טלפון"
                    value={user.phone}
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="hospital-role-label">תפקיד בבית חולים</InputLabel>
                    <Select
                      labelId="hospital-role-label"
                      value={user.hospitalRole}
                      onChange={(e) => setUser({ ...user, hospitalRole: e.target.value })}
                      label="תפקיד בבית חולים"
                    >
                      {hospitalRoles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          {role.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <PermissionsManager 
                  roles={user.roles}
                  hospitals={hospitals}
                  loggedInSuperAdmin={loggedInUser.isSuperAdmin}
                  isSuperAdmin={user.isSuperAdmin}
                  onChangeSuperAdmin={handleSuperAdminChange}
                  onRoleChange={handleRoleChange}
                  onAddRole={addRole}
                  onRemoveRole={removeRole}
                />
                <Grid item xs={12} className="submit-button-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>                  
                  <LoadingSubmitButton
                    isLoading={isSubmitting}
                    text="צור"
                    loadingText="יוצר..."
                    style={{
                      backgroundColor: '#103C6E',
                      color: 'white',
                      minWidth: '200px',
                      '&:hover': {
                        backgroundColor: '#0d3157',
                      },
                      '&:disabled': {
                        backgroundColor: '#B9EDE7',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </ThemeProvider>
      </CacheProvider>
    </CommonLayout>
  );
}

export default AddUser;