import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protocols from "./pages/Protocols";
import AddEditProtocol from "./pages/AddEditProtocol";
import Hospitals from "./pages/Hospitals";
import Login from "./pages/Login";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import ProtocolViewer from "./pages/ProtocolViewer";
import Training from "./pages/Training";

const AppRoutes = ({ authToken, setToken }) => {
  // Check localStorage if no token is provided in props
  if (!authToken) {
    authToken = localStorage.getItem("token");
  }

  // If not authenticated, only allow access to login page
  if (!authToken) {
    return (
      <Routes>
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );
  }

  // If authenticated, provide access to all routes
  return (
    <Routes>
      <Route path="/login" element={<Navigate replace to="/protocols" />} />
      <Route path="/protocols" element={<Protocols />} />
      <Route path="/protocols/new" element={<AddEditProtocol />} />
      <Route path="/protocols/:id/view" element={<ProtocolViewer />} />
      <Route path="/protocols/:id/edit" element={<AddEditProtocol />} />
      <Route path="/hospitals" element={<Hospitals />} />
      <Route path="/profile" element={<EditUser />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/new" element={<AddUser />} />
      <Route path="/users/:id" element={<EditUser />} />
      <Route path="/training" element={<Training />} />
      <Route path="/" element={<Navigate replace to="/protocols" />} />
      <Route path="*" element={<Navigate replace to="/protocols" />} />
    </Routes>
  );
};

export default AppRoutes;
