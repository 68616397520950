import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import LoadingSubmitButton from '../components/LoadingSubmitButton';
import '../config/env.js';

function Login({ setToken }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [focusedInput, setFocusedInput] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    
    try {
      const response = await axios.post(`${window._env_.REACT_APP_API_BASE_URL}/api/auth/login`, { email, password });
      const token = response.data.token;
      login(token);
      setToken(token);
      localStorage.setItem('token', token);
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data.message : 'Server error');
      setError('התחברות נכשלה. אנא בדוק את הפרטים ונסה שוב.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" dir="rtl" style={{ backgroundColor:"#1FB5A3", height: "100%" }}>
      <form onSubmit={handleSubmit} style={{
        width: '80dvw',
        maxWidth: '350px',
        padding: '20px'        
      }}>
        <div className="form-group mb-3 position-relative">
          <input 
            type="email" 
            id="inputEmail" 
            className="form-control pt-4" 
            required 
            autoFocus 
            value={email} 
            onChange={e => setEmail(e.target.value)}
            onFocus={() => setFocusedInput('email')}
            onBlur={() => setFocusedInput('')}
          />
          <label 
            htmlFor="inputEmail" 
            className="position-absolute text-muted"
            style={{
              right: '12px',
              top: '0',
              transition: 'all 0.3s ease',
              transform: focusedInput === 'email' || email ? 'translateY(4px) scale(0.75)' : 'translateY(12px)',
              transformOrigin: 'right top'
            }}
          >
            כתובת אימייל
          </label>
        </div>
        <div className="form-group mb-3 position-relative">
          <input 
            type="password" 
            id="inputPassword" 
            className="form-control pt-4" 
            required 
            value={password} 
            onChange={e => setPassword(e.target.value)}
            onFocus={() => setFocusedInput('password')}
            onBlur={() => setFocusedInput('')}
          />
          <label 
            htmlFor="inputPassword" 
            className="position-absolute text-muted"
            style={{
              right: '12px',
              top: '0',
              transition: 'all 0.3s ease',
              transform: focusedInput === 'password' || password ? 'translateY(4px) scale(0.75)' : 'translateY(12px)',
              transformOrigin: 'right top'
            }}
          >
            סיסמה
          </label>
        </div>
        <div className="text-center">
          <LoadingSubmitButton
            isLoading={isSubmitting}
            text="התחבר"
            loadingText="מתחבר..."
            style={{
              backgroundColor: '#103C6E',
              color: 'white',
              width: '100%',
              height: '48px',
              fontSize: '1.25rem',
              fontWeight: '500'
            }}
            className="btn-lg"
          />
        </div>
        <div style={{height: "150px"}}> 
        </div>
        {error && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center', fontSize: '0.9em' }}>{error}</div>}
      </form>
    </div>
  );
}

export default Login;