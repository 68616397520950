// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-submit-button {
    position: relative;
    min-height: 40px;
    min-width: 120px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loading-submit-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .snake-loader {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .snake-segment {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: currentColor;
    animation: snake-wave 0.6s ease-in-out infinite;
  }
  
  .snake-segment:nth-child(1) {
    animation-delay: -0.4s;
  }
  
  .snake-segment:nth-child(2) {
    animation-delay: -0.2s;
  }
  
  .snake-segment:nth-child(3) {
    animation-delay: 0s;
  }
  
  .loading-text {
    margin-left: 8px;
  }
  
  @keyframes snake-wave {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/LoadingSubmitButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,8BAA8B;IAC9B,+CAA+C;EACjD;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".loading-submit-button {\n    position: relative;\n    min-height: 40px;\n    min-width: 120px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: all 0.3s ease;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .loading-submit-button:disabled {\n    cursor: not-allowed;\n    opacity: 0.7;\n  }\n  \n  .snake-loader {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n  }\n  \n  .snake-segment {\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    background-color: currentColor;\n    animation: snake-wave 0.6s ease-in-out infinite;\n  }\n  \n  .snake-segment:nth-child(1) {\n    animation-delay: -0.4s;\n  }\n  \n  .snake-segment:nth-child(2) {\n    animation-delay: -0.2s;\n  }\n  \n  .snake-segment:nth-child(3) {\n    animation-delay: 0s;\n  }\n  \n  .loading-text {\n    margin-left: 8px;\n  }\n  \n  @keyframes snake-wave {\n    0%, 100% {\n      transform: translateY(0);\n    }\n    50% {\n      transform: translateY(-6px);\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
