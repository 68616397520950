import axios from 'axios';
import './env.js';

const API = axios.create({
  baseURL: window._env_.REACT_APP_API_BASE_URL
});

// Synchronous GET request function
API.getSync = function(url, config = {}) {
  const token = localStorage.getItem('token');
  const xhr = new XMLHttpRequest();
  const fullUrl = this.defaults.baseURL + url;
  
  // Open request synchronously (false parameter makes it sync)
  xhr.open('GET', fullUrl, false);
  
  // Set headers
  xhr.setRequestHeader('Content-Type', 'application/json');
  if (token) {
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  }
  
  // Add custom headers from config
  if (config.headers) {
    Object.keys(config.headers).forEach(header => {
      xhr.setRequestHeader(header, config.headers[header]);
    });
  }
  
  try {
    xhr.send();
    
    if (xhr.status >= 200 && xhr.status < 300) {
      return {
        data: JSON.parse(xhr.responseText),
        status: xhr.status,
        statusText: xhr.statusText,
        headers: xhr.getAllResponseHeaders(),
        config: config
      };
    } else {
      // Handle error similar to the async interceptor
      const errorObject = {
        code: xhr.status,
        message: xhr.responseText,
        hebMessage: null // Add if available in your error format
      };
      
      if (window.showErrorDialog && !url.includes('/login')) {
        window.showErrorDialog(errorObject);
      }
      
      throw errorObject;
    }
  } catch (error) {
    throw error;
  }
};

API.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Exclude login errors
    if (error.config.url.includes('/login')) {
      return Promise.reject(error);
    }

    // Create an error object to be used by the ErrorDialog
    const errorObject = {
      code: error.response?.status,
      message: error.response?.data?.error?.message,
      hebMessage: error.response?.data?.error?.hebMessage,
    };

    // Dispatch an action to show the error dialog
    if (window.showErrorDialog) {
      window.showErrorDialog(errorObject);
    }

    return Promise.reject(error);
  }
);

export default API;