// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loader.css */
.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    z-index: 9999; /* Higher z-index to cover everything */
    pointer-events: all; /* Ensure it blocks all interactions */
  }
  
  .loader-container {    
    padding: 2rem;
    /* border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  }
  
  .loader {
    width: 100px;
    height: 100px;
    position: relative;
  }
  
  @keyframes breath {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); }
  }
  
  .breath-animation {
    animation: breath 2s ease-in-out infinite;
  }`, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,0CAA0C;IAC1C,0BAA0B;IAC1B,aAAa,EAAE,uCAAuC;IACtD,mBAAmB,EAAE,sCAAsC;EAC7D;;EAEA;IACE,aAAa;IACb;wFACoF;EACtF;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,WAAW,mBAAmB,EAAE;IAChC,MAAM,qBAAqB,EAAE;EAC/B;;EAEA;IACE,yCAAyC;EAC3C","sourcesContent":["/* Loader.css */\n.loader-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(255, 255, 255, 0.8);\n    backdrop-filter: blur(4px);\n    z-index: 9999; /* Higher z-index to cover everything */\n    pointer-events: all; /* Ensure it blocks all interactions */\n  }\n  \n  .loader-container {    \n    padding: 2rem;\n    /* border-radius: 0.5rem;\n    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */\n  }\n  \n  .loader {\n    width: 100px;\n    height: 100px;\n    position: relative;\n  }\n  \n  @keyframes breath {\n    0%, 100% { transform: scale(1); }\n    50% { transform: scale(1.2); }\n  }\n  \n  .breath-animation {\n    animation: breath 2s ease-in-out infinite;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
