import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import Header from '../components/Header';
import ProtocolDetailsDialog from '../components/ProtocolDetailsDialog';
import { useProtocolFile } from '../services/protocolFileService';
import RecentProtocolsManager from '../components/RecentProtocolsManager';
import Loader from '../components/Loader';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './ProtocolViewer.css';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const findHebrewNameById = (id, hospitalId, hospitals) => {
  if (!id || !hospitalId) {
    return '';
  }
  const hospital = hospitals.find(h => h._id === hospitalId);
  if (!hospital) {
    return '';
  }
  const department = hospital.departments.find(dept => dept._id === id);
  return department ? department.nameHebrew : '';
};

const ProtocolViewer = () => {
  // State management
  const [protocol, setProtocol] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.6);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hooks and context
  const { id } = useParams();
  const { hospitals } = useContext(UserContext);
  const { protocolAccessed } = RecentProtocolsManager();
  const { signedUrl, loading: urlLoading } = useProtocolFile(protocol?.currentRevision?.url);

  // Track protocol access
  useEffect(() => {
    if (id) {
      protocolAccessed(id);
    }
  }, [id, protocolAccessed]);

  // Fetch protocol data
  useEffect(() => {
    const fetchProtocol = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await API.get(`/api/protocols/view/${id}`);
        setProtocol(response.data);
        
        if (response.data.currentRevision.type === 'AZURE_BLOB') {
          setPdfUrl(response.data.currentRevision.url);
        }
      } catch (error) {
        console.error('Error fetching protocol:', error);
        setError(error.response?.data?.message || 'Failed to load protocol');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchProtocol();
    }
  }, [id]);

  // Fetch PDF data in production environment
  useEffect(() => {
    if (signedUrl && process.env.NODE_ENV !== 'development') {
      const fetchPdfData = async () => {
        try {
          const pdfResponse = await fetch(signedUrl);
          if (!pdfResponse.ok) {
            throw new Error(`HTTP error! status: ${pdfResponse.status}`);
          }
          const arrayBuffer = await pdfResponse.arrayBuffer();
          setPdfData(arrayBuffer);
        } catch (error) {
          console.error('Error fetching PDF:', error);
          setError('Failed to load PDF file');
        }
      };
      fetchPdfData();
    }
  }, [signedUrl]);

  // PDF handlers
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);    
  };

  const onDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Failed to load PDF document');
  };

  // Zoom handlers
  const zoomIn = () => setScale(prevScale => Math.min(prevScale + 0.2, 3));
  const zoomOut = () => setScale(prevScale => Math.max(prevScale - 0.2, 0.5));

  // Dialog handlers
  const handleShowDetails = () => setShowDetails(true);
  const handleCloseDetails = () => setShowDetails(false);

  // Download handler
  const handleDownload = async () => {
    if (!pdfUrl) return;

    try {
      const downloadUrl = signedUrl || pdfUrl;
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `protocol-${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError('Failed to download PDF');
    }
  };

  // Loading and error states
  if (isLoading || urlLoading) {
    return (
      <div style={{ position: 'relative', height: '100vh' }}>
        <Loader isLoading={true} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!protocol) {
    return (
      <div className="error-container">
        <p>Protocol not found</p>
      </div>
    );
  }

  return (
    <>
      <Header onDownload={handleDownload} onShowDetails={handleShowDetails} />
      <div className="protocol-viewer">
        {protocol.currentRevision.type === 'RICH_TEXT' ? (
          <div className="protocol-content">
            {protocol.body?.content}
          </div>
        ) : (
          <div>
            <div className="pdf-controls">
              <button 
                onClick={zoomOut}
                className="zoom-button"
                title="Zoom out"
              >
                −
              </button>
              <span className="zoom-level">{Math.round(scale * 100)}%</span>
              <button 
                onClick={zoomIn}
                className="zoom-button"
                title="Zoom in"
              >
                +
              </button>
              {numPages && <span className="page-count">Total Pages: {numPages}</span>}
            </div>
            
            <div className="pdf-container">
              {process.env.NODE_ENV !== 'development' ? (
                // In production, use the binary data
                pdfData && (
                  <Document
                    file={pdfData}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    loading={<div className="pdf-loading">Loading PDF...</div>}
                    error={<div className="pdf-error">Failed to load PDF</div>}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page 
                        key={`page_${index + 1}`} 
                        pageNumber={index + 1} 
                        scale={scale}
                        width={Math.min(window.innerWidth * 0.9, 1000)}
                        loading={<div className="page-loading">Loading page...</div>}
                        error={<div className="page-error">Error loading page</div>}
                      />
                    ))}
                  </Document>
                )
              ) : (
                // In development, use the URL directly
                pdfUrl && (
                  <Document
                    file={signedUrl || pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    loading={<div className="pdf-loading">Loading PDF...</div>}
                    error={<div className="pdf-error">Failed to load PDF</div>}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page 
                        key={`page_${index + 1}`} 
                        pageNumber={index + 1} 
                        scale={scale}
                        width={Math.min(window.innerWidth * 0.9, 1000)}
                        loading={<div className="page-loading">Loading page...</div>}
                        error={<div className="page-error">Error loading page</div>}
                      />
                    ))}
                  </Document>
                )
              )}
            </div>
          </div>
        )}
      </div>

      {protocol && (
        <ProtocolDetailsDialog
          open={showDetails}
          onClose={handleCloseDetails}
          protocol={protocol}
          department={findHebrewNameById(
            protocol.departments[0],
            protocol.hospital._id,
            hospitals
          )}
        />
      )}
    </>
  );
};

export default ProtocolViewer;