import React from 'react';
import './LoadingSubmitButton.css';

const LoadingSubmitButton = ({ 
  isLoading = false, 
  text = 'Submit',
  loadingText = '',
  className = '',
  style = {},
  type = 'submit',
  onClick,
  disabled = false
}) => {
  return (
    <button
      type={type}
      className={`loading-submit-button ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <div className="snake-loader">
          <div className="snake-segment"></div>
          <div className="snake-segment"></div>
          <div className="snake-segment"></div>
          <span className="loading-text">{loadingText}</span>
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default LoadingSubmitButton;