import React from 'react';
import './Loader.css';
import LogoImage from '../assets/logo/IconOnly_Transparent_NoBuffer.png';

const Loader = ({ isLoading = false }) => {
  if (!isLoading) return null;

  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div className="loader breath-animation">
            <img className="loader" src={LogoImage} alt="Loading..." />
        </div>
      </div>
    </div>
  );
};

export default Loader;