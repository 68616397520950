import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, useLocation  } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import { 
  TextField, Button, Select, MenuItem, FormControl, InputLabel, 
  Chip, Dialog, DialogTitle, DialogContent, DialogActions, 
  Grid, Typography, Paper, FormHelperText
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { useDropzone } from 'react-dropzone';
import CommonLayout from '../components/CommonLayout';
import Loader from '../components/Loader';
import LoadingSubmitButton from '../components/LoadingSubmitButton';
import './AddEditProtocol.css';
import { primaryButtonStyle, secondaryButtonStyle, dialogActionsStyle } from '../utils/sharedStyles';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#1FB5A3',
          color: 'white',
          margin: '0 0 4px 4px',
        },
        deleteIcon: {
          color: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#103C6E',
          '&:hover': {
            backgroundColor: '#0d3157',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          textAlign: 'right',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(-14px, -9px) scale(0.75)',
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 16px) scale(1)',
          },
        },
      },
    },
  },
});

function AddEditProtocol() {
  const { user, hospitals, createDepartment } = useContext(UserContext);
  const [protocol, setProtocol] = useState({
    name: '',
    hospital: '',
    department: '',
    tags: [],
    file: null,
    currentRevision: null
  });
  const [showModal, setShowModal] = useState(false);
  const [newDepartment, setNewDepartment] = useState({ nameHebrew: '', nameEnglish: '' });
  const [newTag, setNewTag] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const isSingleHospitalUser = user && user.roles.length === 1 && !user.isSuperAdmin;

  useEffect(() => {
    if (user) {
      if (isSingleHospitalUser) {
        const userHospital = user.roles[0].hospital;
        setProtocol(prev => ({ ...prev, hospital: userHospital }));
      } else if (!id) {
        // Handle the query parameter for new protocols
        const searchParams = new URLSearchParams(location.search);
        const defaultDepartmentId = searchParams.get('dep');
        if (defaultDepartmentId) {
          const hospital = hospitals.find(h => 
            h.departments.some(d => d._id === defaultDepartmentId)
          );
          if (hospital) {
            setProtocol(prev => ({
              ...prev,
              hospital: hospital._id,
              department: defaultDepartmentId
            }));
          }
        }
      }
    }
    if (id) {
      fetchProtocol();
    }
  }, [user, id, isSingleHospitalUser, hospitals, location.search]);

  const fetchProtocol = async () => {
    setIsLoading(true);
    try {
      const response = await API.get(`/api/protocols/view/${id}`);
      const fetchedProtocol = {
        name: response.data.name || '',
        hospital: response.data.hospital?._id || '',
        department: response.data.departments?.[0] || '',
        tags: response.data.tags || [],
        file: null,
        currentRevision: response.data.currentRevision
      };
      setProtocol(fetchedProtocol);
    } catch (error) {
      console.error('Fetching protocol failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!protocol.name.trim()) newErrors.name = 'שדה חובה';
    if (!protocol.hospital) newErrors.hospital = 'שדה חובה';
    if (!protocol.department) newErrors.department = 'שדה חובה';
    if (!id && !protocol.file) newErrors.file = 'שדה חובה';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('name', protocol.name);
      formData.append('hospital', protocol.hospital);
      formData.append('departments[]', protocol.department);
      protocol.tags.forEach(tag => formData.append('tags[]', tag));
      if (protocol.file) {
        formData.append('file', protocol.file);
      }

      const endpoint = id ? `/api/protocols/${id}` : '/api/protocols';
      const method = id ? 'put' : 'post';
      await API[method](endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      navigate('/protocols');
    } catch (error) {
      console.error('Submitting protocol failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleHospitalChange = (e) => {
    const newHospitalId = e.target.value;
    setProtocol(prev => ({ ...prev, hospital: newHospitalId, department: '' }));
    setErrors(prev => ({ ...prev, hospital: '', department: '' }));
  };

  const handleCreateDepartment = async () => {
    try {
      const createdDepartment = await createDepartment(protocol.hospital, newDepartment);
      setProtocol(prev => ({
        ...prev,
        department: createdDepartment._id
      }));
      setShowModal(false);
      setNewDepartment({ nameHebrew: '', nameEnglish: '' });
    } catch (error) {
      console.error('Creating department failed:', error);
    }
  };

  const handleAddTag = (e) => {
    if (e.key === 'Enter' && newTag.trim() !== '') {
      e.preventDefault();
      setProtocol({
        ...protocol,
        tags: [...protocol.tags, newTag.trim()]
      });
      setNewTag('');
    }
  };

  const handleAddTagOnBlur = (e) => {
    if (newTag.trim() !== '') {      
      setProtocol({
        ...protocol,
        tags: [...protocol.tags, newTag.trim()]
      });
      setNewTag('');
    }
  };


  const handleRemoveTag = (tagToRemove) => {
    setProtocol({
      ...protocol,
      tags: protocol.tags.filter(tag => tag !== tagToRemove)
    });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setProtocol({ ...protocol, file: acceptedFiles[0] });
      setErrors(prev => ({ ...prev, file: '' }));
    } else if (rejectedFiles.length > 0) {
      setErrors(prev => ({ ...prev, file: 'רק קבצי PDF מותרים' }));
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {'application/pdf': ['.pdf']},
    multiple: false
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('he-IL', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  return (
    <CommonLayout>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="add-edit-protocol-container" style={{ position: 'relative' }}>
            <div className="protocol-header">
              <Typography variant="h5" style={{fontWeight:"bold", padding: "5px", marginBottom: "20px", textAlign: "center"}}>
                {id ? 'עריכת פרוטוקול' : 'פרוטוקול חדש'}
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="שם"
                    value={protocol.name}
                    onChange={(e) => setProtocol({ ...protocol, name: e.target.value })}
                    error={!!errors.name}
                    helperText={errors.name}
                    required
                  />
                </Grid>
                {!isSingleHospitalUser && (
                  <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.hospital}>
                      <InputLabel>בית חולים</InputLabel>
                      <Select
                        value={protocol.hospital}
                        onChange={handleHospitalChange}
                        required
                      >
                        {hospitals.map(hospital => (
                          <MenuItem key={hospital._id} value={hospital._id}>
                            {hospital.nameHebrew}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.hospital && <FormHelperText>{errors.hospital}</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.department}>
                    <InputLabel>מחלקה</InputLabel>
                    <Select
                      value={protocol.department}
                      onChange={(e) => {
                        setProtocol({ ...protocol, department: e.target.value });
                        setErrors(prev => ({ ...prev, department: '' }));
                      }}
                      required
                      disabled={!protocol.hospital}
                    >
                      {protocol.hospital && hospitals.find(h => h._id === protocol.hospital)?.departments.map(department => (
                        <MenuItem key={department._id} value={department._id}>
                          {department.nameHebrew}
                        </MenuItem>
                      ))}
                      {protocol.hospital && (
                        <MenuItem>
                          <Button 
                            fullWidth
                            onClick={() => setShowModal(true)}
                            style={{backgroundColor:'transparent', color: "#103C6E"}}
                          >
                            צור מחלקה
                          </Button>
                        </MenuItem>
                      )}
                    </Select>
                    {errors.department && <FormHelperText>{errors.department}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="תגיות"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    onBlur={handleAddTagOnBlur}                    
                    onKeyPress={handleAddTag}
                    placeholder="הקלד תגית ולחץ אנטר להוספה"
                  />
                  <div className="tags-container">
                    {protocol.tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleRemoveTag(tag)}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Paper {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} ${errors.file ? 'error' : ''}`}>
                    <input {...getInputProps()} />
                    <Typography variant="body1" align="center">
                      {protocol.file ? 
                        protocol.file.name :
                        isDragActive ?
                          'שחרר את הקובץ כאן ...' :
                          'גרור קובץ PDF לכאן, או לחץ על מנת לבחור קובץ'
                      }
                    </Typography>
                    {id && protocol.currentRevision && !protocol.file && (
                      <Typography variant="body2" align="center">
                        גרסה אחרונה הועלתה ב {formatDate(protocol.currentRevision.createdAt)}
                      </Typography>
                    )}
                  </Paper>
                  {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
                </Grid>
                <Grid item xs={12} className="submit-button-container">
                  <LoadingSubmitButton
                    isLoading={isSubmitting}
                    text={id ? 'עדכן' : 'צור'}
                    loadingText={id ? 'מעדכן...' : 'יוצר...'}
                    style={{
                      backgroundColor: '#103C6E',
                      color: 'white',
                      minWidth: '200px',
                      '&:hover': {
                        backgroundColor: '#0d3157',
                      }
                    }}
                  />
              </Grid>
              </Grid>
            </form>

            <Dialog open={showModal} onClose={() => setShowModal(false)}>
              <DialogTitle>צור מחלקה חדשה</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="שם המחלקה בעברית"
                  fullWidth
                  value={newDepartment.nameHebrew}
                  onChange={(e) => setNewDepartment({...newDepartment, nameHebrew: e.target.value})}
                  required
                />
                <TextField
                  margin="dense"
                  label="שם המחלקה באנגלית"
                  fullWidth
                  value={newDepartment.nameEnglish}
                  onChange={(e) => setNewDepartment({...newDepartment, nameEnglish: e.target.value})}
                  required
                />
              </DialogContent>
              <DialogActions style={dialogActionsStyle}>
                <Button style={secondaryButtonStyle} onClick={() => setShowModal(false)}>ביטול</Button>
                <Button style={primaryButtonStyle} onClick={handleCreateDepartment} color="primary">צור מחלקה</Button>
              </DialogActions>              
            </Dialog>

            <Loader isLoading={isLoading} />
          </div>
        </ThemeProvider>
      </CacheProvider>
    </CommonLayout>
  );
}

export default AddEditProtocol;