import React from "react";
import { Timer } from "lucide-react";

const Training = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-8">
      {/* Main content container */}
      <div className="max-w-2xl rounded-lg bg-white p-12 shadow-lg">
        <div className="flex flex-col items-center space-y-6 text-center">
          {/* Icon */}
          <div className="rounded-full bg-blue-50 p-4">
            <Timer className="h-8 w-8 text-blue-600" />
          </div>

          {/* Title */}
          <h1 className="text-4xl font-semibold text-blue-900">Coming Soon</h1>

          {/* Description */}
          <p className="text-lg text-gray-600">
            This section is currently under development
          </p>

          {/* Progress indicator */}
          <div className="w-full max-w-md">
            <div className="h-2 w-full rounded-full bg-gray-100">
              <div className="h-2 w-1/3 rounded-full bg-blue-500 transition-all duration-1000"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Training;
